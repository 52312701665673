import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { translate } from "../../../components/Language/Translate";
import { simpleRequest } from "../../simpleRequest";
import { StyledDivWithBorder } from "../../../components/styledComponents/divs";
import SpeciesFormFields from "./SpeciesFormFields";
import { useDispatch } from "react-redux";
import { addSpeciesOnApi } from "../../../actions/species";

const SpeciesNew = ({ props }) => {
  // console.log({props});
  const { speciesGroupId, setLocalForm } = props;
  const dispatch = useDispatch();
  const initialValues = {
    deDE: "",
    enUK: "",
  };
  const [variables, setVariables] = useState({ ...initialValues });

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(addSpeciesOnApi({ ...variables, speciesGroupId }));
    // console.log(variables);
    // const newSpecies = (await simpleRequest({url:'species', method:"post", data:{...variables, speciesGroupId}})).data?.species || {};
    // setSpecies(species => [...species, newSpecies]);
    setVariables({...initialValues});
    setLocalForm("main");
  };
  const handleCancel = async (e) => {
    setLocalForm("main");
  };

  return (
    <StyledDivWithBorder>
      <h4>{translate("Add Item")}</h4>
      <Form>
        <SpeciesFormFields props={{ ...props, variables, setVariables }} />
        <button
          className="btn btn-outline-dark"
          onClick={(e) => handleSubmit(e)}
        >
          {translate("Save")}
        </button>
        <button
          className="btn btn-outline-dark ml-1"
          onClick={(e) => handleCancel(e)}
        >
          {translate("Close")}
        </button>
      </Form>
    </StyledDivWithBorder>
  );
};
export default SpeciesNew;
