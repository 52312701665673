import {ADD_SPECIES_OBSERVATION, DELETE_SPECIES_OBSERVATION, FETCH_SPECIES_OBSERVATIONS, UPDATE_SPECIES_OBSERVATION} from "./types";
import {simpleRequest} from "../newForms2/simpleRequest"

export function fetchSpeciesObservationsFromAPI() {
  
  return async function (dispatch) {
    const response = await simpleRequest({url:`speciesobservations`, method:"get"});
    // console.log('_______________________________', response)
    const speciesObservations = response.data?.speciesObservations || [];
    return dispatch(getSpeciesObservations(speciesObservations));
  };
};
function getSpeciesObservations(speciesObservations) {
  return {
    type: FETCH_SPECIES_OBSERVATIONS,
    speciesObservations
  };
};


export function addSpeciesObservationOnApi(data){
  return async function(dispatch){
    const response = await simpleRequest({url:`speciesobservations`, method:"post", data})
    const speciesObservation = response.data?.speciesObservation;
    // console.log({speciesObservation})
    return dispatch(addSpeciesObservation(speciesObservation));
  };
};

function addSpeciesObservation(speciesObservation){
  return{
    type: ADD_SPECIES_OBSERVATION,
    speciesObservation
  };  
};

export function updateSpeciesObservationOnApi(id, data){
  return async function(dispatch){
    const response = await simpleRequest({url:`speciesobservations/${id}`, method:"patch", data});
    const speciesObservation = response.data?.speciesObservation;
    return dispatch(updateSpeciesObservation(speciesObservation));
  };
};
function updateSpeciesObservation(speciesObservation){
  return{
    type: UPDATE_SPECIES_OBSERVATION,
    speciesObservation,
  };
};




export function deleteSpeciesObservationFromApi(id){
  return async function(dispatch){
    const response = await simpleRequest({url:`speciesobservations/${id}`, method: "delete"});
    const deleted = response.data?.deleted;
    return dispatch(deleteSpeciesObservation(deleted));
  };
};

function deleteSpeciesObservation(deleted){
  return{
    type: DELETE_SPECIES_OBSERVATION,
    deleted
  };
};