import { ADD_SPECIES, DELETE_SPECIES, FETCH_SPECIES, UPDATE_SPECIES } from "../actions/types";
// import {sortArrayByObjectField} from '../helpers/sort';

export default function rootReducer(state = [], action) {
  switch (action.type) {
    case FETCH_SPECIES:
      if (!action.species) return state;
      return [...action.species];
    case ADD_SPECIES:
      if (!action.species) return state;
      return [...state, action.species];
    case UPDATE_SPECIES:
      if (!action.species) return state;
      return [
        ...state.filter((sg) => sg.id !== action.species.id),
        action.species,
      ];
    case DELETE_SPECIES:
      // console.log(action);
      if (!action.deleted) return state;
      return [...state.filter((c) => c.id !== action.deleted.id)];
    default:
      return state;
  }
}
