import {
  ADD_SPECIES_OBSERVATION,
  DELETE_SPECIES_OBSERVATION,
  FETCH_SPECIES_OBSERVATIONS,
  UPDATE_SPECIES_OBSERVATION,
} from "../actions/types";
// import {sortArrayByObjectField} from '../helpers/sort';

export default function rootReducer(state = [], action) {
  // console.log({action})
  switch (action.type) {
    case FETCH_SPECIES_OBSERVATIONS:
      // console.log({ action });
      if (!action.speciesObservations) return state;
      return [...action.speciesObservations];
    case ADD_SPECIES_OBSERVATION:
      if (!action.speciesObservation) return state;
      return [...state, action.speciesObservation];
    case UPDATE_SPECIES_OBSERVATION:
      if (!action.speciesObservation) return state;
      return [
        ...state.filter((so) => so.id !== action.speciesObservation.id),
        action.speciesObservation,
      ];
    case DELETE_SPECIES_OBSERVATION:
      // console.log(action);
      if (!action.deleted) return state;
      return [...state.filter((c) => c.id !== action.deleted.id)];
    default:
      return state;
  }
}
