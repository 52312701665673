import React, { useState, useEffect } from "react";
import Fields from "./Fields";
import { Form } from "react-bootstrap";
import { translate } from "../../../components/Language/Translate";
import Spinner from "../../../components/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { updateSpeciesGroupOnApi } from "../../../actions/speciesGroups";

const SpeciesGroupUpdate = ({ props }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { setOpenForm, speciesGroupId } = props;
  const speciesGroups = useSelector((st) => st.speciesGroups);
  const dispatch = useDispatch();

  const [variables, setVariables] = useState({});

  useEffect(() => {
    const fetch = () => {
      const { category, enUK, deDE, latin } = speciesGroups.find(
        (sg) => sg.id === speciesGroupId
      );
      setVariables({ category, enUK, deDE, latin });
      setIsLoading(false);
    };
    if (isLoading) fetch();
  }, [speciesGroups, speciesGroupId, setVariables, isLoading]);
  if (isLoading) return <Spinner />;

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(e.target, {speciesGroupId, dispatch})
    try{
      await dispatch(updateSpeciesGroupOnApi(speciesGroupId, {...variables}))
      setOpenForm("speciesGroupList");
    }catch(err){
      console.log({err})
    }

  };

  // console.log({ variables });

  return (
    <div className="mt-5">
      <h5>{translate("Update Group")}</h5>
      <Form>
        <Fields variables={variables} setVariables={setVariables} />
        <button
          className="btn btn-outline-dark"
          onClick={(e) => handleSubmit(e)}
        >
          {translate("Submit")}
        </button>
        <button
          className="btn btn-outline-secondary ml-1"
          onClick={() => setOpenForm("showSpeciesGroup")}
        >
          {translate("Cancel")}
        </button>
      </Form>
    </div>
  );
};
export default SpeciesGroupUpdate;
