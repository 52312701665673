import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addSpeciesObservationOnApi } from "../../actions/speciesObservations";
import { translate } from "../../components/Language/Translate";
import Spinner from "../../components/Spinner";
import ObservationFields from "./ObservationFields";
import ObservationSchema from "./ObservationSchema";

const ObservationNew = ({ mainProps, initialValues }) => {
  const { setMainLoading, setOpenForm } = mainProps;
  const [isLoading, setIsLoading] = useState(true);
  const [fieldSettings, setFieldSettings] = useState({});
  const [variables, setVariables] = useState({});
  const [fieldErrors, setFieldErrors] = useState({});
  const [serverError, setServerError] = useState("");
  const cruiseId = useSelector((st) => st.currentCruise).id;
  const { species } = mainProps;
  const dispatch = useDispatch();
  const validateField = () => console.log("MISSING");

  useEffect(() => {
    const fetch = async () => {
      // actualLocation(setGeolocation);
      const fields = new ObservationSchema().fields;
      setFieldSettings({ ...fields });
      setVariables({ ...initialValues });
      setIsLoading(false);
    };
    if (isLoading) fetch();
  }, [isLoading, setIsLoading, initialValues]);

  if (isLoading) return <Spinner />;
  const actualSpecies =
    (species && species.find((s) => s.id === variables.speciesId)) || null;
  const fullname =
    (actualSpecies && `${actualSpecies.deDE} / ${actualSpecies.enUK}`) || "";

  const handleChange = (e) =>
    setVariables({ ...variables, [e.target.name]: e.target.value });

  const valid = () => {
    const errors = {};
    const { albumId, geolocation, destinationId, speciesId, dateTime } =
      variables;
    if (!(albumId || destinationId || geolocation))
      errors.geolocation = translate("Required");
    if (!dateTime) errors.dateTime = translate("Required");
    setFieldErrors({ ...errors });
    return Object.values(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(variables, fieldErrors, valid());
    if (!valid()) {
      console.log("Not valid");
      return;
    }
    dispatch(addSpeciesObservationOnApi({ ...variables, cruiseId }));
    // setLocalObservations(speciesObservations.filter(so => so.dateTime === variables.dateTime));
    setVariables({ ...variables, speciesId: 0, species: "" });
  };
  const handleCancel = () => {
    setOpenForm("list");
    setMainLoading(true);
  };

  const props = {
    fieldSettings,
    variables,
    setVariables,
    fieldErrors,
    setFieldErrors,
    serverError,
    setServerError,
    handleChange,
    validateField,
    fullname,
    species,
    handleSubmit,
    handleCancel,
    title:"New Observation"
  };

  return (

      <ObservationFields props={props} />

  );
};

export default ObservationNew;
