import React from "react";
import { Form } from "react-bootstrap";
import { translate } from "../../components/Language/Translate";
import DateTime from "../Fields/DateTime";
import TextArea from "../Fields/TextArea";
import TextFieldWithValues from "./TextFieldWithValues";
import Geolocations from "./geolocations/Geolocations";

const ObservationFields = ({props}) => {
    const {fullname, variables, species, setVariables, handleSubmit, handleCancel} = props;

    
  const FullName = () => {
    return (
      <div>
        <button
          className="btn btn-outline-danger mr-3"
          onClick={() => setVariables({ ...variables, speciesId: 0 })}
        >
          X
        </button>
        <b>{fullname}</b>
      </div>
    );
  };
    


    return(
        <div
        className="my-3 p-3"
        style={{ border: "1px solid grey", borderRadius: "5px" }}
      >
        <h5>{translate("New Observation")}</h5>
        <Form>
          <Geolocations props={props} />
          <DateTime name="dateTime" props={props} />
          {fullname && <FullName />}
          {!fullname && (
            <TextFieldWithValues
              name="speciesInput"
              props={props}
              array={species}
            />
          )}
          <TextArea name="description" props={props}></TextArea>
          <button
            size="lg"
            className="btn btn-outline-dark"
            onClick={handleSubmit}
          >
            {translate("Save")}
          </button>
          <button
            size="lg"
            className="btn btn-outline-secondary ml-2"
            onClick={handleCancel}
          >
            {translate("Close")}
          </button>
  
        </Form>
      </div>


    )
};
export default ObservationFields;