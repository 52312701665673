import {ADD_SPECIES, DELETE_SPECIES, FETCH_SPECIES, UPDATE_SPECIES} from "./types";
import {simpleRequest} from "../newForms2/simpleRequest"

export function fetchSpeciesFromAPI() {
  
  return async function (dispatch) {
    const response = await simpleRequest({url:`species`, method:"get"});
    const species = response.data?.species;
    return dispatch(getSpecies(species));
  };
};
function getSpecies(species) {
  return {
    type: FETCH_SPECIES,
    species
  };
};

export function addSpeciesOnApi(data){
  return async function(dispatch){
    const response = await simpleRequest({url:`species`, method:"post", data})
    const species = response.data?.species;
    return dispatch(addSpecies(species));
  };
};

function addSpecies(species){
  return{
    type: ADD_SPECIES,
    species
  };  
};

export function updateSpeciesOnApi(id, data){
  return async function(dispatch){
    const response = await simpleRequest({url:`species/${id}`, method:"patch", data});
    const species = response.data?.species;
    return dispatch(updateSpecies(species));
  };
};
function updateSpecies(species){
  return{
    type: UPDATE_SPECIES,
    species,
  };
};




export function deleteSpeciesFromApi(id){
  return async function(dispatch){
    const response = await simpleRequest({url:`species/${id}`, method: "delete"});
    const deleted = response.data?.deleted;
    return dispatch(deleteSpecies(deleted));
  };
};

function deleteSpecies(deleted){
  return{
    type: DELETE_SPECIES,
    deleted
  };
};
