import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSpeciesFromAPI } from "../../../../actions/species";
import { fetchSpeciesGroupsFromAPI } from "../../../../actions/speciesGroups";
import { fetchSpeciesObservationsFromAPI } from "../../../../actions/speciesObservations";
import { getNewDateObject } from "../../../../helpers/Date";
import ObservationNew from "../../../../newForms2/observations/ObservationNew";
import SpeciesGroupsMain from "../../../../newForms2/observations/speciesGroups/SpeciesGroupsMain";
import { translate } from "../../../Language/Translate";
import Spinner from "../../../Spinner";
import ObservationListCruise from "./ObservationListCruise";

const ObservationMain = () => {
  // console.count("RENDER ObservationMain")
  const [mainLoading, setMainLoading] = useState(true);
  const dispatch = useDispatch();
  const [openForm, setOpenForm] = useState("list"); // list, addObservation, addSpeciesGroup, updateSpeciesGroup
  const speciesGroups = useSelector((st) => st.speciesGroups);
  const species = useSelector((st) => st.species);
  const speciesObservations = useSelector((st) => st.speciesObservations);

  // console.log({speciesGroups, species, speciesObservations});

  useEffect(() => {
    const fetch = async () => {
      if (!speciesGroups.length) dispatch(fetchSpeciesGroupsFromAPI());

      if (!species.length) dispatch(fetchSpeciesFromAPI());

      if (!speciesObservations.length)
        dispatch(fetchSpeciesObservationsFromAPI());
      (() => setMainLoading(false))();
    };
    if (mainLoading) fetch();
  }, [mainLoading]);

  const newArr = [];
  speciesGroups.forEach((sg) => {
    species
      .filter((s) => s.speciesGroupId === sg.id)
      .forEach((s) => {
        newArr.push({ group: sg.deDE, species: s.deDE, en: s.enUK });
      });
  });
  //   console.log({ newArr });

  if (mainLoading) return <Spinner />;

  const mainProps = {
    mainLoading,
    setMainLoading,
    openForm,
    setOpenForm,
    speciesGroups,
    species,
    speciesObservations,
  };
  const initialValues = {
    dateTime: getNewDateObject(new Date()),
    geolocation: "",
    destinationId: 0,
    albumId: 0,
    speciesId: 0,
    description: "",
  };
  // console.log({mainProps});
  return (
    <div>
      {openForm === "list" && (
        <button
          className="btn btn-outline-dark"
          onClick={() => setOpenForm("addObservation")}
        >
          {translate("Add new Observation")}
        </button>
      )}
      {openForm === "list" && (
        <button
          className="btn btn-outline-dark ml-1"
          onClick={() => setOpenForm("speciesGroupList")}
        >
          {translate("Groups")}
        </button>
      )}
      {openForm === "addObservation" && (
        <ObservationNew mainProps={mainProps} initialValues={initialValues} />
      )}
      {openForm === "list" && <ObservationListCruise mainProps={mainProps} />}
      {openForm.includes("peciesGroup") && (
        <SpeciesGroupsMain mainProps={mainProps} />
      )}
    </div>
  );
};

export default ObservationMain;
