import {
  ADD_SPECIES_GROUP,
  DELETE_SPECIES_GROUP,
  FETCH_SPECIES_GROUPS,
  UPDATE_SPECIES_GROUP,
} from "../actions/types";

export default function rootReducer(state = [], action) {
  switch (action.type) {
    case FETCH_SPECIES_GROUPS:
      if (!action.speciesGroups) return state;
      return [...action.speciesGroups];

    case ADD_SPECIES_GROUP:
      if (!action.speciesGroup) return state;
      return [...state, action.speciesGroup];
    case UPDATE_SPECIES_GROUP:
      if (!action.speciesGroup) return state;
      return [
        ...state.filter((sg) => sg.id !== action.speciesGroup.id),
        action.speciesGroup,
      ];
    case DELETE_SPECIES_GROUP:
      // console.log(action);
      if (!action.deleted) return state;
      return [...state.filter((c) => c.id !== action.deleted.id)];
    default:
      return state;
  }
}
