import React, { useState, useEffect } from "react";
import { simpleRequest } from "../../../../newForms2/simpleRequest";
import { translate } from "../../../Language/Translate";
import Spinner from "../../../Spinner";
// import ObservationListAll from "./ObservationsListList";
import { StyledDivWithBorder } from "../../../styledComponents/divs";
import ObservationsList from "./ObservationsList";
import { sortArrayByObjectField } from "../../../../helpers/sort";
import { useDispatch, useSelector } from "react-redux";
import { fetchSpeciesFromAPI } from "../../../../actions/species";
import { fetchSpeciesGroupsFromAPI } from "../../../../actions/speciesGroups";
import { fetchSpeciesObservationsFromAPI } from "../../../../actions/speciesObservations";

const ObservationsAllMain = () => {
  const [mainLoading, setMainLoading] = useState(true);
  const [openForm, setOpenForm] = useState("list"); // list, addObservation, addSpeciesGroup, updateSpeciesGroup
  const [selectedGeolocation, setSelectedGeolocation] = useState(null);
  const dispatch = useDispatch();
  const speciesGroups = useSelector((st) => st.speciesGroups);
  const species = useSelector((st) => st.species);
  const speciesObservations = useSelector((st) => st.speciesObservations);

  useEffect(() => {
    
    const fetch = async () => {
      console.count("useEffect, fetch");
      console.log(
        speciesGroups.length,
        species.length,
        speciesObservations.length
      );
      if (!speciesGroups.length) {
        console.log("FETCH GROUPS");
        dispatch(fetchSpeciesGroupsFromAPI());
      }
      if (!species.length) {
        console.log("FETCH SPECIES");
        dispatch(fetchSpeciesFromAPI());
      }
      if (!speciesObservations.length) {
        console.log("FETCH OBSERVATIONS");
        dispatch(fetchSpeciesObservationsFromAPI());
      }
      (() => setMainLoading(false))();
    };
    if (mainLoading) fetch();
  }, [mainLoading]);

  if (mainLoading) return <Spinner />;

  const mainProps = {
    mainLoading,
    setMainLoading,
    openForm,
    setOpenForm,
    speciesGroups,
    species,
    speciesObservations,
    selectedGeolocation,
    setSelectedGeolocation,
  };
  console.log({ mainProps });
  return (
    <StyledDivWithBorder>
      <h3>{translate("Nature Observations")}</h3>
      {openForm === "list" && <ObservationsList mainProps={mainProps} />}
    </StyledDivWithBorder>
  );
};

export default ObservationsAllMain;
