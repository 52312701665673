
import users from './users';
import currentUser from './currentUser';
import currentCruise from './currentCruise';
import currentVessel from './currentVessel';
import currentCompany from './currentCompany';
import companies from './companies';
import vessels from './vessels';
import cruises from './cruises';
import destinations from './destinations';
import currentDestination from './currentDestination'
import authors from './authors';
import cruiseProposals from './cruiseProposals';
import currentAuthor from './currentAuthor';
import groups from './groups';
import globals from './globals';
import otherUser from './otherUser';
import requests from './requests';
import photoLikes from './photoLikes';
import speciesGroups from './speciesGroups';
import species from './species';
import speciesObservations from './speciesObservations'


import { combineReducers } from "redux";

export default combineReducers({
  currentCruise,
  currentVessel,
  currentUser,
  currentCompany,
  users,
  companies,
  vessels,
  cruises,
  destinations,
  currentDestination,
  authors,
  currentAuthor,
  cruiseProposals,
  groups,
  globals,
  otherUser,
  requests,
  photoLikes,
  speciesGroups,
  species,
  speciesObservations,
});
