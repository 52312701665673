import React, { useState } from "react";
import { Row, Col, Table, Form } from "react-bootstrap";
// import ListOfAlbums from "./ListOfAlbums";
// import ListOfDestinations from "./ListOfDestinations";
import { simpleRequest } from "../../../newForms2/simpleRequest";
import TextField from "../../../newForms2/Fields/TextField";
import ListOfSpeciesGroups from "./ListOfSpeciesGroups";


const ListOfObservationsMain = () => {
    const [value, setValue] = useState("")
    const [observations, setObservations] = useState([]);
    const [species, setSpecies] = useState([]);
    const [groups, setGroups] = useState([]);

    const [view, setView] = useState('none');

    const findEmptyObservations = async() => {
        const _obs = (await simpleRequest({url:"speciesobservations"})).data?.speciesObservations || [];
        const _spe = (await simpleRequest({url:"species"})).data?.species || [];
        const _speG = (await simpleRequest({url:"speciesgroups"})).data?.speciesGroups || [];
        setObservations([..._obs]);
        setSpecies([..._spe]);
        setGroups([..._speG])
    }
    const handleChange = e => setValue(e.target.value);
    const handleClick = async(e) => {
        console.log(e.target.id)

    }

    const empty = observations.filter(o => !o.speciesId);
    const filteredSpecies = species.filter(s => `${s.deDE} / ${s.enUK}`.toLowerCase().includes(value.toLowerCase()));
    console.log({observations, species, groups, empty});
    const props = {observations, setObservations, species, setSpecies, groups, setGroups};


    return(
        <div>
            <Row className="Row">
                <Col>
                    <button className = "btn btn-outline-dark" onClick={() => setView("none")}>{`None`}</button>
                </Col>
                <Col>
                    <button className = "btn btn-outline-dark" onClick={findEmptyObservations}>{`Get Data`}</button>
                </Col>


            </Row>
            {/* {view === "albumsToDestinations" && <ListOfAlbums/>}
            {view === "destinationsToAlbums" && <ListOfDestinations/>} */}
            <Form>
            <Table>
                {empty.map(e => {
                    return(
                        <Row key = {e.id} >
                            <Col>{e.species}</Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Species</Form.Label>
                                    <Form.Control 
                                        value={value}
                                        onChange={e => handleChange(e)}
                                        
                                    ></Form.Control>
                                </Form.Group>
                                <ul>
                                    {filteredSpecies.map(sp => <li id={sp.id} key={sp.id} onClick={e => handleClick(e)}>
                                            {`${sp.deDE} / ${sp.enUK}`}
                                        </li>)}
                                </ul>

                            </Col>
                            
                        </Row>
                    )
                })}
            </Table>
            </Form>
            <ListOfSpeciesGroups props={props}/>
        </div>
    )
};
export default ListOfObservationsMain;