import React, { useState } from "react";
import Fields from "./Fields";
// import { simpleRequest } from "../../simpleRequest";
import { Form } from "react-bootstrap";
import { translate } from "../../../components/Language/Translate";
import { useDispatch } from "react-redux";
import { addSpeciesGroupOnApi } from "../../../actions/speciesGroups";

const SpeciesGroupNew = ({props}) => {

    const {setOpenForm} = props;

    const dispatch = useDispatch()
    const initialValues = {
        category:"Fauna",
        enUK:"",
        deDE:"",
        latin:"",
    };
    const [variables, setVariables] = useState({...initialValues});

    const handleSubmit = async(e) => {
        e.preventDefault()
        dispatch(addSpeciesGroupOnApi(variables));
        setVariables({...initialValues});
        setOpenForm('speciesGroupList');
    };

    return(
        <div className="mt-5">
            <h5>{translate("Add New Group")}</h5>
            <Form >
                <Fields variables={variables} setVariables={setVariables}/>
                <button className="btn btn-outline-dark" onClick={e => handleSubmit(e)}>{translate("Save")}</button>
                <button className="btn btn-outline-secondary ml-1" onClick={() => setOpenForm("speciesGroupList")}>{translate("Cancel")}</button>
            </Form>
        </div>

    )
};
export default SpeciesGroupNew;