import { simpleRequest } from "../newForms2/simpleRequest";
import { ADD_SPECIES_GROUP, DELETE_SPECIES_GROUP, FETCH_SPECIES_GROUPS, UPDATE_SPECIES_GROUP } from "./types";

export function fetchSpeciesGroupsFromAPI() {
  
  return async function (dispatch) {
    const response = await simpleRequest({url:`speciesgroups`, method:"get"});
    const speciesGroups = response.data?.speciesGroups;
    return dispatch(getSpeciesGroups(speciesGroups));
  };
};
function getSpeciesGroups(speciesGroups) {
  return {
    type: FETCH_SPECIES_GROUPS,
    speciesGroups
  };
};


export function addSpeciesGroupOnApi(data){
  return async function(dispatch){
    const response = await simpleRequest({url:`speciesgroups`, method:"post", data})
    const speciesGroup = response.data?.speciesGroup;
    return dispatch(addSpeciesGroup(speciesGroup));
  };
};

function addSpeciesGroup(speciesGroup){
  return{
    type: ADD_SPECIES_GROUP,
    speciesGroup
  };  
};

export function updateSpeciesGroupOnApi(id, data){
  return async function(dispatch){
    const request = {url:`speciesgroups/${id}`, method:"patch", data}
    const response = await simpleRequest(request);
    const speciesGroup = response.data?.speciesGroup;
    console.log({speciesGroup})
    return dispatch(updateSpeciesGroup(speciesGroup));
  };
};
function updateSpeciesGroup(speciesGroup){
  return{
    type: UPDATE_SPECIES_GROUP,
    speciesGroup,
  };
};




export function deleteSpeciesGroupFromApi(id){
  return async function(dispatch){
    const response = await simpleRequest({url:`speciesgroups/${id}`, method: "delete"});
    const deleted = response.data?.deleted;
    return dispatch(deleteSpeciesGroup(deleted));
  };
};

function deleteSpeciesGroup(deleted){
  return{
    type: DELETE_SPECIES_GROUP,
    deleted
  };
};

